define("discourse/plugins/discourse-follow/discourse/models/post-stream", ["exports", "@ember/object", "@ember/object/computed", "@ember-decorators/object", "rsvp", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/models/category", "discourse/models/rest"], function (_exports, _object, _computed, _object2, _rsvp, _ajax, _decorators, _category, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this class implements an interface similar to the `UserStream` class in core
  // (app/models/user-stream.js) so we can use it with the `{{user-stream}}`
  // component (in core as well) which expects a `UserStream` instance.

  class PostStream extends _rest.default {
    loading = false;
    itemsLoaded = 0;
    canLoadMore = true;
    static #_ = (() => dt7948.g(this.prototype, "lastPostCreatedAt", [(0, _computed.reads)("content.lastObject.created_at")]))();
    #lastPostCreatedAt = (() => (dt7948.i(this, "lastPostCreatedAt"), void 0))();
    _initialize() {
      this.set("content", []);
    }
    static #_2 = (() => dt7948.n(this.prototype, "_initialize", [(0, _object2.on)("init")]))();
    noContent(loading, length) {
      return !loading && length === 0;
    }
    static #_3 = (() => dt7948.n(this.prototype, "noContent", [(0, _decorators.default)("loading", "content.length")]))();
    findItems() {
      if (!this.canLoadMore || this.loading) {
        return _rsvp.Promise.resolve();
      }
      this.set("loading", true);
      const data = {};
      if (this.lastPostCreatedAt) {
        data.created_before = this.lastPostCreatedAt;
      }
      return (0, _ajax.ajax)(`/follow/posts/${this.user.username}`, {
        data
      }).then(content => {
        const streamItems = content.posts.map(post => {
          return _object.default.create({
            title: post.topic.title,
            postUrl: post.url,
            created_at: post.created_at,
            category: _category.default.findById(post.category_id),
            topic_id: post.topic.id,
            post_id: post.id,
            post_number: post.post_number,
            username: post.user.username,
            name: post.user.name,
            avatar_template: post.user.avatar_template,
            user_id: post.user.id,
            excerpt: post.excerpt,
            truncated: post.truncated
          });
        });
        return {
          posts: streamItems,
          hasMore: content.extras.has_more
        };
      }).then(_ref => {
        let {
          posts: streamItems,
          hasMore
        } = _ref;
        this.content.addObjects(streamItems);
        this.set("itemsLoaded", this.content.length);
        this.set("canLoadMore", hasMore);
      }).finally(() => {
        this.set("loading", false);
      });
    }
  }
  _exports.default = PostStream;
});