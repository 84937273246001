define("discourse/plugins/discourse-follow/discourse/templates/connectors/user-profile-secondary/follow-statistics-user", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.total_following}}
    {{follow-statistic
      label="user.following.label"
      total=this.model.total_following
    }}
  {{/if}}
  
  {{#if this.model.total_followers}}
    {{follow-statistic
      label="user.followers.label"
      total=this.model.total_followers
    }}
  {{/if}}
  */
  {
    "id": "2deDx7zw",
    "block": "[[[41,[30,0,[\"model\",\"total_following\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"label\",\"total\"],[\"user.following.label\",[30,0,[\"model\",\"total_following\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"total_followers\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"label\",\"total\"],[\"user.followers.label\",[30,0,[\"model\",\"total_followers\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"follow-statistic\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/connectors/user-profile-secondary/follow-statistics-user.hbs",
    "isStrictMode": false
  });
});