define("discourse/plugins/discourse-follow/discourse/templates/connectors/user-card-metadata/follow-statistics-user-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.total_following}}
    <div class="metadata__following">
      <span>{{i18n "user.following.label"}}</span>
      <span class="value">{{this.user.total_following}}</span>
    </div>
  {{/if}}
  {{#if this.user.total_followers}}
    <div class="metadata__followers">
      <span>{{i18n "user.followers.label"}}</span>
      <span class="value">{{this.user.total_followers}}</span>
    </div>
  {{/if}}
  */
  {
    "id": "ePKd7gUf",
    "block": "[[[41,[30,0,[\"user\",\"total_following\"]],[[[1,\"  \"],[10,0],[14,0,\"metadata__following\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"user.following.label\"],null]],[13],[1,\"\\n    \"],[10,1],[14,0,\"value\"],[12],[1,[30,0,[\"user\",\"total_following\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"user\",\"total_followers\"]],[[[1,\"  \"],[10,0],[14,0,\"metadata__followers\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"user.followers.label\"],null]],[13],[1,\"\\n    \"],[10,1],[14,0,\"value\"],[12],[1,[30,0,[\"user\",\"total_followers\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/connectors/user-card-metadata/follow-statistics-user-card.hbs",
    "isStrictMode": false
  });
});