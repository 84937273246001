define("discourse/plugins/discourse-follow/discourse/components/follow-button", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _object, _computed, _ajax, _ajaxError, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.showButton this.canFollow)}}
    <DButton
      @label={{this.labelKey}}
      @icon={{this.icon}}
      @disabled={{this.loading}}
      @action={{action "toggleFollow"}}
    />
  {{/if}}
  */
  {
    "id": "fy8PxphN",
    "block": "[[[41,[28,[37,1],[[30,0,[\"showButton\"]],[30,0,[\"canFollow\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@label\",\"@icon\",\"@disabled\",\"@action\"],[[30,0,[\"labelKey\"]],[30,0,[\"icon\"]],[30,0,[\"loading\"]],[28,[37,3],[[30,0],\"toggleFollow\"],null]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-button.hbs",
    "isStrictMode": false
  });
  class FollowButton extends _component.default {
    loading = false;
    static #_ = (() => dt7948.g(this.prototype, "isFollowed", [(0, _computed.alias)("user.is_followed")]))();
    #isFollowed = (() => (dt7948.i(this, "isFollowed"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "canFollow", [(0, _computed.alias)("user.can_follow")]))();
    #canFollow = (() => (dt7948.i(this, "canFollow"), void 0))();
    showButton(user, currentUser) {
      if (!currentUser) {
        return false;
      }
      if (currentUser.id === user.id) {
        return false;
      }
      if (user.suspended) {
        return false;
      }
      if (user.staged) {
        return false;
      }
      if (user.id < 1) {
        // bot
        return false;
      }
      return true;
    }
    static #_3 = (() => dt7948.n(this.prototype, "showButton", [(0, _decorators.default)("user", "currentUser")]))();
    labelKey(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "follow.unfollow_button_label";
      } else {
        return "follow.follow_button_label";
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "labelKey", [(0, _decorators.default)("isFollowed", "canFollow")]))();
    icon(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "user-xmark";
      } else {
        return "user-plus";
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "icon", [(0, _decorators.default)("isFollowed", "canFollow")]))();
    toggleFollow() {
      const type = this.isFollowed ? "DELETE" : "PUT";
      this.set("loading", true);
      (0, _ajax.ajax)(`/follow/${this.user.username}.json`, {
        type
      }).then(() => {
        this.set("isFollowed", !this.isFollowed);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loading", false);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleFollow", [_object.action]))();
  }
  _exports.default = FollowButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FollowButton);
});