define("discourse/plugins/discourse-follow/discourse/routes/feed", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-follow/discourse/models/post-stream"], function (_exports, _discourse, _postStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Feed extends _discourse.default {
    model() {
      return _postStream.default.create({
        user: this.modelFor("user")
      });
    }
    afterModel(model) {
      return model.findItems();
    }
  }
  _exports.default = Feed;
});