define("discourse/plugins/discourse-follow/discourse/connectors/user-profile-secondary/follow-statistics-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, component) {
      return component.siteSettings.follow_show_statistics_on_profile;
    }
  };
});