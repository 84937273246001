define("discourse/plugins/discourse-follow/discourse/templates/following", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{follow-users-list users=this.users type="following" user=this.user}}
  */
  {
    "id": "os1va30c",
    "block": "[[[1,[28,[35,0],null,[[\"users\",\"type\",\"user\"],[[30,0,[\"users\"]],\"following\",[30,0,[\"user\"]]]]]]],[],false,[\"follow-users-list\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/following.hbs",
    "isStrictMode": false
  });
});