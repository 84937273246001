define("discourse/plugins/discourse-follow/discourse/templates/connectors/user-card-additional-buttons/follow-button-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{follow-button user=this.user}}
  */
  {
    "id": "AEPtUwtD",
    "block": "[[[1,[28,[35,0],null,[[\"user\"],[[30,0,[\"user\"]]]]]]],[],false,[\"follow-button\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/connectors/user-card-additional-buttons/follow-button-container.hbs",
    "isStrictMode": false
  });
});